import axios from "axios";
import React from "react";
import { useState } from "react";
// import { TextField, Grid, Autocomplete } from "@mui/material";
import { useEffect } from "react";
import { baseUrl } from "../../utils/constant_api";
import { Dialog } from "@mui/material";
import { FaCheck } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";

const CorporateList = ({ authUser, metiers, partenaires}) => {
  const [candidats, setCandidats] = useState([]);
  const [categorieSelectionnee, setCategorieSelectionnee] = useState("tous");
  const [profil, setProfil] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showPremiumOnly, setShowPremiumOnly] = useState(false); // Filtre premium
  const [totalCandidats, setTotalCandidats] = useState(0); // Total des candidats
  const [totalPremium, setTotalPremium] = useState(0); // Total des comptes premium
  const [searchQuery, setSearchQuery] = useState(""); // Recherche

  const token = localStorage.getItem("token");


  const close = () => {
    setIsOpen(false);
  };

  const deleteProfil = (id) => {
    axios
      .delete(`${baseUrl}/auth/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          window.location.reload();
        }
      })
      .catch((error) => {
        alert("Une erreur est survenue");
        console.log(error);
      });
  };

  const ProfileDetail = (candidat) => {
    setProfil(candidat);
    setIsOpen(true);
  };

  useEffect(() => {
     // Calculer les statistiques globales
     const total = partenaires?.length;
     const premium = partenaires.filter((profile) => profile.is_premium ).length;
 
     setTotalCandidats(total);
     setTotalPremium(premium);


    if (categorieSelectionnee === "tous" || categorieSelectionnee === undefined) {
      setCandidats(partenaires?.filter((partenaire) => partenaire?.type === "recruteur"));
    } else {
      setCandidats(
        partenaires.filter(
          (partenaire) =>
          partenaire?.type === "recruteur" &&
          partenaire?.recruteur === categorieSelectionnee
        )
      );
    }
  }, [partenaires, categorieSelectionnee]);
  console.log(candidats)

  return (
    <div className="px-4 py-16 mt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
          Liste des recruteurs present sur l'application
        </p>
      </div>

       {/* Affichage des statistiques */}
       <div className="mb-6 flex justify-between items-center">
          <p className="text-lg font-bold border rounded-md px-4 p-2 text-white bg-blue-600">
            Total des candidats : {totalCandidats}
          </p>
          <p className="text-lg font-bold border rounded-md px-4 p-2 text-white bg-green-600">
            Comptes Premium : {totalPremium}
          </p>
        </div>
        <div>
            <Dialog open={isOpen}>
              <div
                role="alert"
                className="rounded-xl border border-gray-100 bg-white p-4 w-full"
              >
                <div className="relative block overflow-hidden rounded-lg border w-full border-gray-100 p-4 sm:p-6 lg:p-8 max-w-lg mx-2 mt-2">
                  <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>
                  <div
                    className="bg-red-300 w-full rounded-md text-center items-center justify-center cursor-pointer p-2"
                    onClick={() => deleteProfil(profil.id)}
                  >
                    Supprimer le profil
                  </div>
    
                  <div className="flex border-black border-b-2 justify-between mb-2 p-2 items-center w-full">
                    <span className="font-bold">
                      {" "}
                      {profil?.recruteur?.entreprise}{" : "} {profil?.firstName} 
                    </span>
                  </div>
                  <div className="my-4 w-full">
                    <div className="w-36 h-36 mx-auto">
                      <img
                        className="rounded-full object-cover w-full h-full shadow-lg"
                        alt=""
                        src={profil.images?.length > 0 ? profil?.images[0] : profil}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between gap-4">
                    <div className="w-full">
                      <div className="flex items-center">
                        <div className="w-1/2 flex">
                          <span className="font-medium">Ville : </span>
                        </div>
                        <div className="w-1/2 flex">
                          <span className="font-bold mx-2">
                            {profil?.ville}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center w-full">
                        <div className="w-1/2 flex items-start justify-start">
                          <span className="font-medium">Entreprise : </span>
                        </div>
                        <div className=" flex w-1/2">
                          <span className=" font-bold mx-2">
                            {profil?.recruteur?.entreprise}
                          </span>
                        </div>
                      </div>
    
                      <div className="flex items-center ">
                        <div className="w-1/2 flex items-start justify-start text-blue-600">
                          <span className="font-medium">Télephone : </span>
                        </div>
                        <div className="w-2/4 flex">
                          <span className="font-bold mx-2  text-blue-600">
                            {profil?.phoneNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
                <div className="flex justify-center mt-4">
                  <button
                    className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                    onClick={close}
                  >
                    fermer
                  </button>
                </div>
              </div>
            </Dialog>
        </div>
      <div className="grid gap-10 mx-auto lg:max-w-screen-lg grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
        {candidats?.map((candidat) => (
          <div
            className="flex flex-col items-center cursor-pointer"
            key={candidat?.id}
            onClick={() => ProfileDetail(candidat)}
          >
            <img
              className="object-cover w-20 h-20 mb-2 rounded-full shadow"
              src={candidat?.images[0]}
              alt="Person"
            />
            <div className="flex flex-col items-center">
            
              <p className="text-lg font-bold text-gray-800">
                {candidat?.recruteur?.entreprise}
              </p>
              <p className="text-gray-800">
                {candidat?.recruteur?.phone}
              </p>
              <span
                className={
                  candidat?.is_premium
                    ? "bg-green-500 px-4 rounded text-white"
                    : "bg-red-500 px-4 rounded text-white"
                }
              >
                {candidat?.is_premium ? "Actif" : "Inactif"}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CorporateList;
