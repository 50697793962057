import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../utils/constant_api";
import { Dialog } from "@mui/material";
import moment from "moment/moment"; 


const AdminTravelPage = () => {
  const [agences, setAgences] = useState([]); 
  const [profil, setProfil] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  
  console.log(agences)

  const token = localStorage.getItem("token");


  const close = () => {
    setIsOpen(false);
  };
  const ProfileDetail = (candidat) => {
    console.log(candidat);
    setProfil(candidat);
    setIsOpen(true);
  };
  const deleteProfil = (id) => {
    axios
      .delete(`${baseUrl}/auth/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          window.location.reload();
        }
      })
      .catch((error) => {
        alert("Une erreur est survenue");
        console.log(error);
      });
  };


  useEffect(() => {
    axios.get(`${baseUrl}/agences`)
    .then((res) => {
      setAgences(res?.data)
    }).catch((error) => {
      console.log(error)
      alert('une erreur est survenu')
    })

  }, [])
  return (
    <div className="px-4 py-16 mt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

      <div>
          <Dialog open={isOpen}>
            <div
              role="alert"
              className="rounded-xl border border-gray-100 bg-white p-4"
            >
              <div className="relative block overflow-hidden rounded-lg border w-full border-gray-100 p-4 sm:p-6 lg:p-8 max-w-lg mx-2 mt-2">
                <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>
                <div
                  className="bg-red-300 w-full rounded-md text-center items-center justify-center cursor-pointer p-2"
                  onClick={() => deleteProfil(profil.id)}
                >
                  Supprimer le profil
                </div>
  
                <div className="flex border-black border-b-2 justify-between mb-2 p-2 items-center">
                  <span className="font-bold">
                    {" "}
                    {profil?.lastName}{" : "} {profil?.firstName} 
                  </span>
                </div>
                <div className="my-4">
                  <div className="w-36 h-36 mx-auto">
                    <img
                      className="rounded-full object-cover w-full h-full shadow-lg"
                      alt=""
                      src={profil.images?.length > 0 ? profil?.images[0] : profil}
                    />
                  </div>
                </div>
                <div className="flex justify-between gap-4">
                  <div className="w-full">
                    <div className="flex items-center">
                      <div className="w-1/2 flex">
                        <span className="text-sm font-medium">Ville depart : </span>
                      </div>
                      <div className="w-1/2 flex">
                        <span className="text-sm font-bold mx-2">
                          {profil?.profil?.ville}, {profil?.profil?.pays}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center w-full">
                      <div className="w-1/2 flex items-start justify-start">
                        <span className="text-sm font-medium">Nombre de voyage crée : </span>
                      </div>
                      <div className=" flex">
                        <span className="text-sm font-bold mx-2">
                          {profil?.voyages?.length}
                        </span>
                      </div>
                    </div>
  
                    <div className="flex items-center ">
                      <div className="w-1/2 flex items-start justify-start text-blue-600">
                        <span className="text-sm font-medium">Télephone : </span>
                      </div>
                      <div className="w-2/4 flex">
                        <span className="text-sm font-bold mx-2  text-blue-600">
                          {profil?.phoneNumber}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center ">
                      <div className="w-1/2 flex items-start justify-start text-blue-600">
                        <span className=" font-medium">Numéro OM : </span>
                      </div>
                      <div className="w-2/4 flex">
                        <span className=" font-bold mx-2  text-blue-600">
                          {profil?.profil?.num_finance_orange}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center ">
                      <div className="w-1/2 flex items-start justify-start text-blue-600">
                        <span className="font-medium">Numéro OM : </span>
                      </div>
                      <div className="w-2/4 flex">
                        <span className=" font-bold mx-2  text-blue-600">
                          {profil?.profil?.num_finance_mtn}
                        </span>
                      </div>
                    </div>

                    {(profil?.voyages && profil?.voyages?.length > 0) &&  (
                      <div className="flex items-center ">
                      <div className="w-1/2 flex items-start justify-start text-blue-600">
                        <span className="font-medium">Date du dernier voyage : </span>
                      </div>
                      <div className="w-2/4 flex">
                        <span className="font-bold mx-2  text-blue-600">
                            {moment(profil?.voyages[profil.voyages.length-1].date_de_depart).format("DD/MM/YYYY")}
                        </span>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
  
                <div className="mt-4">
                  <p className="text-pretty text-sm text-gray-600 text-justify">
                    {profil?.profil?.biographie}
                  </p>
                </div>
              </div>
  
              <div className="flex justify-center mt-4">
                <button
                  className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                  onClick={close}
                >
                  fermer
                </button>
              </div>
            </div>
          </Dialog>
      </div>

      <div className="grid gap-10 mx-auto lg:max-w-screen-lg grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
        {agences.map((candidat) => (
          <div
            className="flex flex-col items-center cursor-pointer"
            key={candidat?.id}
            onClick={() => ProfileDetail(candidat)}
          >
            <img
              className="object-cover w-20 h-20 mb-2 rounded-full shadow"
              src={candidat?.images[0]}
              alt="Person"
            />
            <div className="flex flex-col items-center">
              <p className="text-lg font-bold">
                {candidat?.lastName}{" "}
              </p>
              <p className="text-sm text-gray-800">
              {candidat?.firstName} {""}
              </p>
            </div>
          </div>
        ))}
      </div>


    </div>
  );
};

export default AdminTravelPage;
