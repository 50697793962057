import React, { useState } from 'react'
import "./Widget.css"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import { useEffect } from 'react';
import { getProfiles } from '../../api';
import { CircularProgress } from '@mui/material';

const Widget = ({type}) => {

    const [profiles, setProfiles] = useState([]);
    const [totalCandidats, setTotalCandidats] = useState(0);
    const [totalTech, setTotalTech] = useState(0);
    const [totalPremium, setTotalPremium] = useState(0);
    const [isLoading, setIsloading] = useState(true);
    

     useEffect(()  => {
      const loadData = async () => {
      getProfiles(setProfiles)

      // Calculer les statistiques globales
      const total = profiles.length;
      const premium = profiles.filter((profile) =>  profile.is_premium).length;
      const totaTech = profiles.filter(
        (profile) =>
          profile.type === "technicien" || profile.type === "prestataire"
      ).length;

      setTotalCandidats(total);
      setTotalPremium(premium);
      setTotalTech(totaTech);
    
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setIsloading(false);
  }
  loadData()
    }, [profiles, setProfiles, setTotalCandidats, setTotalPremium, setTotalTech])
 
  let data; 

    switch(type){
      case "user": 
      data= {
        amout: totalCandidats,
        title: "utilisateurs",
        isMoney: false,
        link: "voir tout les utilisateurs",
        icon: <AccountCircleIcon className='widget-icon'/>
      };
      break; 
      case "artisans": 
      data= {
        amout: totalTech,
        title: "Techniciens",
        isMoney: false,
        link: "voir tout les artisans",
        icon: <ManageAccountsIcon className='widget-icon' style={{backgroundColor: "green", color:"white"}}/>
      };
      break; 
      case "projets": 
      data= {
        amout: totalPremium,
        title: "COMPTES PREMIUM",
        isMoney: false,
        link: "voir tout les projets",
        icon: <AssuredWorkloadIcon className='widget-icon' style={{backgroundColor: "blue", color:"white"}}/>
      };
      break;
      default:
        break;
    }

  return (
    <div> 
      {isLoading ? (
        <div className="loader">
          Chargement...
          <CircularProgress />
        </div> 
      ) : (
        <div className="widget">
          <div className="widget-left">
            <span className="widget-title">{data.title}</span>
            <span className="text-xl font-semibold text-blue-600">{data.isMoney && "$"} {data.amout}</span>
            <span className="widget-link">{data.link}</span>
          </div>
          <div className="widget-right">
            {data.icon}
          </div>
        </div>
      )}
    </div>
  )
}

export default Widget