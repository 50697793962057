import React from 'react'
import Category from './Category/Category'
import Widget from '../../components/Widget/Widget'

const AdmiHome = () => {
  

  return (
    <div className='w-full'>
      <div className="widgets  px-8">
          <Widget type="user"/>
          <Widget type="artisans" />
          <Widget type="projets" />
        </div>
      <Category/>
    </div>
  )
}

export default AdmiHome
