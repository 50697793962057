import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../utils/constant_api";
import { Alert, Stack } from "@mui/material";
import UpdatePlan from "./UpdatePlan";

const CreatePlans = () => {
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [prix, setPrix] = useState("");
  const [type, setType] = useState("");

  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");

  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const DeletePlan = (plan) => {
    const token = localStorage.getItem("token");

    axios
      .delete(`${baseUrl}/plan/${plan.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          fetchPlans(); // Refresh the plans list
        }
      })
      .catch((error) => console.error("Erreur lors de la suppression :", error));
  };

  const fetchPlans = () => {
    axios.get(`${baseUrl}/plans`).then((res) => {
      setPlans(res.data);
    });
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("prix", prix);
    formData.append("description", description);
    formData.append("duration", duration);
    formData.append("nom", nom);
    formData.append("type", type);

    try {
      await axios.post(`${baseUrl}/plans`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      fetchPlans(); // Refresh the plans list
      setNom("");
      setDescription("");
      setDuration("");
      setPrix("");
      setType("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.response?.data?.message || "Une erreur est survenue");
      setLoading(false);
    }
  };

  return (
    <div className="py-16 flex justify-center ">
      <div className="w-full mx-auto">
        {/* Formulaire de création de plans */}
        <form
          onSubmit={handleSubmit}
          className="gap-2 border rounded-xl w-full shadow-xl mt-4 max-w-xl mx-2 p-4"
        >
          {errorMessage && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{errorMessage}</Alert>
            </Stack>
          )}

          <div>
            <h1 className="text-lg font-bold my-2">Création de plan d'abonnement</h1>
          </div>

          <div className="lg:flex gap-2">
            <div className="w-full my-2">
              <label htmlFor="titre" className="text-sm font-medium">
                Titre de l'abonnement
              </label>
              <input
                placeholder="Titre de l'abonnement"
                className="w-full border rounded-lg p-2"
                type="text"
                id="titre"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </div>
          </div>

          <div className="gap-2">
            <div className="w-full my-2">
              <label htmlFor="description" className="font-medium text-sm">
                Description du plan
              </label>
              <textarea
                placeholder="Description du plan"
                className="w-full border rounded-lg p-2"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="relative mb-2 w-full">
              <select
                className="w-full pl-12 pr-3 py-2 outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Type d'abonnement</option>
                <option value="Inscription">Inscription</option>
                <option value="recruteur">Recruteur</option>
                <option value="technicien">Technicien</option>
                <option value="user">Employé</option>
              </select>
            </div>

            <div className="w-full my-2">
              <label htmlFor="prix" className="font-medium text-sm">
                Prix du plan
              </label>
              <input
                className="w-full border rounded-lg p-2"
                type="number"
                id="prix"
                value={prix}
                onChange={(e) => setPrix(e.target.value)}
              />
            </div>
          </div>

          <div className="w-full gap-2">
            <label htmlFor="duration" className="font-medium">
              Durée du plan (en jours)
            </label>
            <input
              placeholder="Durée du plan"
              className="w-full border rounded-lg p-2"
              type="number"
              id="duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </div>

          {loading ? (
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          ) : (
            <button type="submit" className="bg-blue-700 py-1 px-4 rounded-lg text-white mt-4">
              Créer un plan
            </button>
          )}
        </form>

        {/* Liste des plans */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 mt-8">
          {plans.map((plan) => (
            <div
              className="divide-y divide-gray-200 rounded-2xl border border-gray-200 shadow-sm"
              key={plan.id}
            >
              <div className="p-6">
                <h2 className="text-xl font-medium text-blue-600 border p-1 border-blue-600 my-2">
                  {plan.nom}
                </h2>
                <p className="font-medium text-gray-500">{getText(plan.description)}</p>
                <p>
                  <strong className="text-3xl font-bold text-gray-900">
                    {plan.prix.split(".")[0]} FCFA
                  </strong>
                  <span className="text-sm font-medium text-gray-700"> /mois</span>
                </p>

                <div className="flex justify-between mt-4">
                  <button
                    className="bg-yellow-500 text-white py-2 px-4 rounded-lg"
                    onClick={() => setSelectedPlan(plan)}
                  >
                    Modifier
                  </button>
                  <button
                    className="bg-red-600 text-white py-2 px-4 rounded-lg"
                    onClick={() => DeletePlan(plan)}
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modale pour la mise à jour */}
      {selectedPlan && (
        <UpdatePlan
          planToUpdate={selectedPlan}
          onClose={() => setSelectedPlan(null)}
          onUpdate={fetchPlans}
        />
      )}
    </div>
  );
};

export default CreatePlans;
