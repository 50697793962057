import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/constant_api";
import { Alert, Stack } from "@mui/material";

const UpdatePlan = ({ planToUpdate, onClose, onUpdate }) => {
  const [nom, setNom] = useState(planToUpdate.nom || "");
  const [description, setDescription] = useState(planToUpdate.description || "");
  const [duration, setDuration] = useState(planToUpdate.duration || "");
  const [prix, setPrix] = useState(planToUpdate.prix || "");
  const [type, setType] = useState(planToUpdate.type || "");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("nom", nom);
    formData.append("description", description);
    formData.append("duration", duration);
    formData.append("prix", prix);
    formData.append("type", type);

    try {
      await axios.put(`${baseUrl}/plan/${planToUpdate.id}`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      onUpdate(); // Inform the parent component to refresh the plans list
      onClose(); // Close the update modal
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.message || "Une erreur est survenue");
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-md shadow-lg">
        <form onSubmit={handleSubmit}>
          <h2 className="text-lg font-bold mb-4">Modifier le plan</h2>

          {errorMessage && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{errorMessage}</Alert>
            </Stack>
          )}

          <div className="mb-4">
            <label htmlFor="nom" className="block text-sm font-medium mb-1">
              Titre de l'abonnement
            </label>
            <input
              id="nom"
              type="text"
              className="w-full border rounded-lg p-2"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="description" className="block text-sm font-medium mb-1">
              Description
            </label>
            <textarea
              rows={5}
              id="description"
              className="w-full border rounded-lg p-2"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="type" className="block text-sm font-medium mb-1">
              Type d'abonnement
            </label>
            <select
              id="type"
              className="w-full border rounded-lg p-2"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">Choisir un type</option>
              <option value="Inscription">Inscription</option>
              <option value="recruteur">Recruteur</option>
              <option value="technicien">Technicien</option>
              <option value="user">Employé</option>
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="prix" className="block text-sm font-medium mb-1">
              Prix
            </label>
            <input
              id="prix"
              type="number"
              className="w-full border rounded-lg p-2"
              value={prix}
              onChange={(e) => setPrix(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="duration" className="block text-sm font-medium mb-1">
              Durée (jours)
            </label>
            <input
              id="duration"
              type="number"
              className="w-full border rounded-lg p-2"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className="bg-gray-400 text-white py-2 px-4 rounded-lg mr-2"
              onClick={onClose}
            >
              Annuler
            </button>
            <button
              type="submit"
              className={`bg-blue-600 text-white py-2 px-4 rounded-lg ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? "En cours..." : "Mettre à jour"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePlan;
